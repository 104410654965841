import React from 'react';
import PropTypes from 'prop-types';
import storage from 'local-storage-fallback'
import _ from "underscore";
import classNames from 'classnames';
import Modal from '@components/vendor/modal';
import AnimateHeight from 'react-animate-height';
import MediaQuery from 'react-responsive';
import BreakPoints from '@services/breakpoints';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import moment from 'moment';

import withLoader from '@components/common/with_loader_decorator';
import AddressDistrictSelector from '@components/selectors/address_district_selector';
import ProductCustomizer from '@components/product_customizers/product_customizer';
import DateFilterSelector from '@components/selectors/date_filter_selector';


class HeroHomepageSelector extends React.Component {
    static propTypes = {
        client_id: PropTypes.number,
        removeMe:PropTypes.string,

        initSelectedCityId: PropTypes.number,
        initSelectedDistrictId: PropTypes.number,
        initSelectedAddressId: PropTypes.number,

        services: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            main: PropTypes.bool.isRequired,
            experimental: PropTypes.bool.isRequired,
            service_type: PropTypes.string.isRequired,
            sales_page_path: PropTypes.string.isRequired,
            funnel_path: PropTypes.string.isRequired,
            icon_on_light_path: PropTypes.string.isRequired,
            hovered_icon_on_light_path: PropTypes.string.isRequired,
            icon_on_dark_path: PropTypes.string.isRequired,
        })),

        cities: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            alternative_name: PropTypes.string.isRequired,
            districts: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                alternative_name: PropTypes.string.isRequired,
            })),
            supported_service_ids: PropTypes.arrayOf(PropTypes.number),
        })),

        addresses: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            city_id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        })),

        icons: PropTypes.shape({
            glass_doors: PropTypes.string,
            single_windows: PropTypes.string,
            large_windows: PropTypes.string,
            single_french_windows: PropTypes.string,
            large_french_windows: PropTypes.string,
        }),

        only_club_discount: PropTypes.bool,

        whats_included_text: PropTypes.object,
        close_button_image: PropTypes.string,

        isMember: PropTypes.bool,
    };

    static defaultProps = {
        only_club_discount: false,
    };

    state = {
        selectedServiceId: null,

        selectedAddressId: null,
        selectedCityId: null,
        selectedDistrictId: null,

        isSubmitted: false,

        renderAddresses: this.props.addresses.length > 0,

        moreServicesOpen: false,

        selectedProductOptions: {},

        productSelectionValid: {},

        whatsIncludedDialogOpen: false,

        whatsIncludedSectionExpanded: false,

        selectedFilter: 'all',
        filterDate: null,

    };

    constructor(props) {
        super(props);
        var initSelectedCity = this.findCity(props.initSelectedCityId)
        if (initSelectedCity) {
            this.state.selectedCityId = props.initSelectedCityId;
            var initSelectedDistrict = initSelectedCity.districts.find(district => district.id === props.initSelectedDistrictId)
            if (initSelectedDistrict) {
                this.state.selectedDistrictId = props.initSelectedDistrictId;
            }
        }

        if (this.findAddress(props.initSelectedAddressId)) {
            this.state.selectedAddressId = props.initSelectedAddressId;
        }

        var previouslySelectedCityId = parseInt(storage.getItem('previouslySelectedCityId'));
        var previouslySelectedDistrictId = parseInt(storage.getItem('previouslySelectedDistrictId'));

        if (_.isNull(this.state.selectedCityId) && previouslySelectedCityId) {
            var city = this.findCity(previouslySelectedCityId);
            if (city) {
                this.state.selectedCityId = previouslySelectedCityId;
                if (city.districts.find(district => district.id === previouslySelectedDistrictId)) {
                    this.state.selectedDistrictId = previouslySelectedDistrictId;
                }
            }
        }

        if (_.isNull(this.state.selectedCityId)) {
            var city = this.props.cities[0]
            if (city) {
                this.state.selectedCityId = city.id;
                this.state.selectedDistrictId = city.districts[0] ? city.districts[0].id : null;
            }
        }

        if (this.state.renderAddresses && !this.state.selectedAddressId) {
            this.state.selectedAddressId = _.last(this.props.addresses).id;
        }

        _.each(props.services, function (service) {
            this.state.selectedProductOptions[service.service_type] = {}
            this.state.productSelectionValid[service.service_type] = false;
        }.bind(this));

        this.state.productSelectionValid['housemaid'] = true;
        this.state.productSelectionValid['housemaid_recruitment'] = true;
        this.state.productSelectionValid['facade_cleaning'] = true;
    }

    componentDidMount() {
        const placeholderDiv = document.getElementById('home-hero-placeholder');
        if (placeholderDiv) {
            placeholderDiv.remove();
        }
    }

    selectedLocationCity = () => {
        if (this.state.selectedAddressId) {
            return this.findCity(this.selectedAddress().city_id)
        } else if (this.state.selectedCityId) {
            return this.selectedCity();
        } else {
            return null;
        }
    }

    currentLocationSupportsService = (service) => {
        var selectedCity = this.selectedLocationCity();
        if (selectedCity) {
            return _.contains(selectedCity.supported_service_ids, service.id)
        } else {
            return true;
        }
    }


    services = () => {
        if (this.state.moreServicesOpen)
            return this.mainServices().concat(this.secondaryServices());
        else
            return this.mainServices();
    };

    mainServices = () => {
        let result =  _.filter(this.props.services, (service) => {
            return service.main;
        });

        if(this.props.only_club_discount)
            result = this.selectfServices(result)

        return result;
    };

    secondaryServices = () => {
        let result =  _.filter(this.props.services, (service) => {
            return !service.main;
        });

        if(this.props.only_club_discount)
            result = this.selectClubServices(result)

        return result;
    };

    selectClubServices = (services) => {
        return _.filter(services, (service) => {
            return service.club_discount_applicable;
        });
    };

    selectedAddress = () => {
        if (this.state.selectedAddressId) {
            return this.findAddress(this.state.selectedAddressId);
        } else {
            return null;
        }
    };

    selectedCity = () => {
        if (this.state.selectedCityId) {
            return this.findCity(this.state.selectedCityId);
        } else {
            return null;
        }
    };

    selectedService = () => {
        if (this.state.selectedServiceId) {
            return this.findService(this.state.selectedServiceId);
        } else {
            return null;
        }
    };

    findCity = (cityId) => {
        return this.props.cities.find(city => city.id === cityId);
    };

    findService = (serviceId) => {
        return this.props.services.find(service => service.id === serviceId);
    };

    findAddress = (addressId) => {
        return this.props.addresses.find(address => address.id === addressId);
    };

    productOptionsUrlParams = () => {
        return _.map(this.currentlySelectedProductOptions(), function (num, key) {
            return "&product_data[" + key + "]=" + num;
        }).join("");
    };

    filterParams = () => {
        if(this.dateFilterDisabled())
            return ''

        let params = "&filter=" + this.state.selectedFilter;
        if (this.state.selectedFilter === 'date') {
            params += '&filter_date=' + moment(this.state.filterDate).format('YYYY-MM-DD');
        }
        return params;
    }

    productSelectionValid = () => {
        return this.state.productSelectionValid[this.selectedService().service_type];
    }

    currentlySelectedProductOptions = () => {
        let selectedOptions =  _.clone(this.state.selectedProductOptions[this.selectedService().service_type]);

        if(this.selectedService().service_type === 'end_of_tenancy_cleaning')
            selectedOptions = {...selectedOptions, ...this.state.selectedProductOptions['carpet_and_upholstery_cleaning']};

        return selectedOptions;
    }

    // is valid holds if the selection is ok to continue if it's mandatory
    itemsUpdated = (serviceType, selectedProductOptions, isValid, totalPrice) => {
        this.setState(prevState => {
            let newProductOptions = _.clone(prevState.selectedProductOptions);
            newProductOptions[serviceType] = {...this.state.selectedProductOptions[serviceType], ...selectedProductOptions};
            let newProductSelectionValid = _.clone(this.state.productSelectionValid);
            newProductSelectionValid[serviceType] = isValid;
            return {selectedProductOptions: newProductOptions, productSelectionValid: newProductSelectionValid};
        });
        // storage.setItem(ServicePageCustomizer.localStorageKey + serviceType, JSON.stringify(updatedItems))
    };

    handleCitySelection = (selectedOption) => {
        this.setState({selectedCityId: selectedOption.value, selectedDistrictId: null}, function () {
            this.deselectServiceIfUnavailable();
        }.bind(this));
        storage.setItem('previouslySelectedCityId', selectedOption.value);
    };

    handleDistrictSelection = (selectedOption) => {
        this.setState({selectedDistrictId: selectedOption.value});
        storage.setItem('previouslySelectedDistrictId', selectedOption.value)
    };

    handleAddressSelection = (selectedOption) => {
        if (selectedOption.value === -1) {
            this.setState({renderAddresses: false, selectedAddressId: null});
        } else {
            this.setState({selectedAddressId: selectedOption.value}, function () {
                this.deselectServiceIfUnavailable();
            }.bind(this));
            return true;
        }
    };

    deselectServiceIfUnavailable = () => {
        let selectedService = this.selectedService();
        if (selectedService && !this.currentLocationSupportsService(selectedService)) {
            this.setState({selectedServiceId: null});
        }
    };

    handleMoreServicesClick = () => {
        this.setState({moreServicesOpen: !this.state.moreServicesOpen});
    };

    handleSelectService = (serviceId) => {
        let service = this.findService(serviceId);
        if (serviceId && !this.currentLocationSupportsService(service))
            return;

        if (this.state.selectedServiceId === serviceId)
            this.setState({selectedServiceId: null, whatsIncludedSectionExpanded: false})
        else {
            let newState = {selectedServiceId: serviceId, whatsIncludedSectionExpanded: false};
            this.setState(newState)
        }
    }

    handleWhatsIncludedDesktopClick = () => {
        this.setState({whatsIncludedSectionExpanded: !this.state.whatsIncludedSectionExpanded})
    }

    reportToAmplitude = () => {
        if(this.selectedService().service_type === 'housemaid') {
            amplitude.track('click housemaid funnel see prices', { product_type: this.state.selectedProductOptions['housemaid']['product_type'],
                date_filter: this.state.filterDate || "all"
            });
        }

    }

    handleSubmit = () => {
        if (!this.productSelectionValid()) {
            alert(i18n.t('components.customizers.product-errors.' + this.selectedService().service_type));
            return;
        }
        if (!this.currentLocationSupportsService(this.selectedService())) {
            alert(i18n.t("components.customizers.not-offered-in-city"));
            return;
        }

        if(this.selectedService().service_type === 'housemaid' && this.state.selectedFilter === 'date'
            && this.state.filterDate > moment().add(14, 'days').toDate() && this.state.selectedProductOptions['housemaid']['product_type'] == 'single_visit') {
            alert(i18n.t("components.customizers.cannot-select-so-far"));
            return;
        }

        if (this.state.selectedAddressId != null) {
            this.reportToAmplitude();
            this.props.showScreenLoader();
            window.location.href = this.selectedService().funnel_path + '?address_id=' + this.state.selectedAddressId + this.productOptionsUrlParams() + this.filterParams();
        } else if (this.state.selectedDistrictId != null) {
            this.props.showScreenLoader();
            this.reportToAmplitude();

            window.location.href = this.selectedService().funnel_path + '?district_id=' + this.state.selectedDistrictId + this.productOptionsUrlParams() + this.filterParams();
        } else if (this.props.addresses.length > 0) {
            alert(i18n.t("components.service-address-selector.no-address-error"));
        } else {
            alert(i18n.t("components.service-district-selector.no-district-error"));
        }
    }

    showSubmitButton = () => {
        return this.selectedService() && !this.selectedService().experimental;
    }

    dateFilterDisabled = () => {
        return this.selectedService() && (this.selectedService().service_type === 'housemaid_recruitment');
    }

    handleFilterSelect = (newFilter, filterDate) => {
        this.setState({selectedFilter: newFilter, filterDate: filterDate});
    }

    submitTextForService = (service) => {
        if (service.service_type === 'housemaid_recruitment') {
            return i18n.t("home.hero-widget.cta-recruitment");
        } else {
            return i18n.t("home.hero-widget.cta-see-prices");
        }
    }

    openWhatsIncludedDialog = () => {
        this.setState({whatsIncludedDialogOpen: true});
    };

    closeWhatsIncludedDialog = () => {
        this.setState({whatsIncludedDialogOpen: false});
    };

    renderUnavailabilityOverlay = (service) => {
        if (!this.currentLocationSupportsService(service)) {
            var tooltip = (
                <Tooltip id={'unavailability-tooltip-' + service.service_type}>
                    {i18n.t("components.customizers.not-offered-in-city")}
                </Tooltip>
            );
            return (
                <OverlayTrigger placement="top" overlay={tooltip}>
                    <div className="unavailable-service-overlay"></div>
                </OverlayTrigger>
            );
        };
    };

    renderModal = () => {
        return (
            <Modal size="large" isOpen={this.state.whatsIncludedDialogOpen} onHide={this.closeWhatsIncludedDialog}
                   dialogClassName="home-whats-included-dialog">
                <div className="custom-modal-header">
                    <div className="close-button" onClick={this.closeWhatsIncludedDialog}></div>
                    <div className="above-header">
                        {i18n.t('home.hero-widget.modal-whats-included')}
                    </div>
                    {this.selectedService() &&
                    <div className="dialog-header-container">
                        {this.selectedService().name}
                    </div>}
                </div>
                <div className="custom-modal-content">
                    {this.selectedService() &&
                    <div
                        dangerouslySetInnerHTML={{__html: this.props.whats_included_text[this.selectedService().service_type]}}/>}

                    <div className="close-button-container">
                        <button onClick={this.closeWhatsIncludedDialog}
                                className={'btn btn-primary polyxena-submit-button'}>{i18n.t('form.buttons.close')}</button>
                    </div>
                </div>
            </Modal>
        );
    };

    renderDesktopService = (service, boxClass = '') => {
        return (
            <div className={classNames({
                "service-box": true,
                'available-service': this.currentLocationSupportsService(service)
            }) + ' ' + boxClass}
                 key={'desktop-' + service.service_type}
                 onClick={_.partial(this.handleSelectService, service.id)}>
                <div className="icon-container">
                    <img src={service.icon_on_light_path} alt={service.name} height={'80px'}
                         className={classNames({'normal-icon': service.main})}/>
                    {service.main &&
                    <img src={service.hovered_icon_on_light_path} alt={service.name}  height={'80px'}
                         className={classNames({'hovered-icon': true})}/>
                    }
                </div>
                <div className="text-container">
                    {service.name}
                </div>
                {this.renderUnavailabilityOverlay(service)}
            </div>
        );
    }


    render() {
        return (
            <div className={'hero-selector-container'} >
                <div className="customizer-with-padding">
                    <div className="customizer-location-date-selector-container">
                        <div className="row">
                            <div className="col-sm-12 col-md-8">
                                <div className="location-header customizer-step-header">
                                    1. {i18n.t('home.hero-widget.select-location')}
                                </div>
                                <AddressDistrictSelector selectedCityId={this.state.selectedCityId}
                                                         selectedDistrictId={this.state.selectedDistrictId}
                                                         selectedAddressId={this.state.selectedAddressId}
                                                         addresses={this.props.addresses}
                                                         cities={this.props.cities}
                                                         onSelectDistrict={this.handleDistrictSelection}
                                                         onSelectCity={this.handleCitySelection}
                                                         onSelectAddress={this.handleAddressSelection}
                                                         renderAddresses={this.state.renderAddresses}
                                                         hideDistrict={true}
                                />
                            </div>
                            <div className="col-sm-12 col-md-4 when-filter-col">
                                <div className="location-header customizer-step-header customizer-step-header-second">
                                    2. {i18n.t('home.hero-widget.select-date')}
                                </div>
                                    <DateFilterSelector selectedFilter={this.state.selectedFilter}
                                                        handleFilterSelect={this.handleFilterSelect}
                                                        selectedDate={this.state.filterDate}
                                                        disabled={this.dateFilterDisabled()}
                                    />
                            </div>
                        </div>
                    </div>

                    <div className="main-services-container">
                        <div className="select-service-header customizer-step-header customizer-select-service-step">
                            3. {i18n.t('home.hero-widget.select-service')}
                        </div>
                        <MediaQuery maxWidth={BreakPoints.smMax}>
                            {_.map(this.services(), function (service) {
                                return (
                                    <div className={'service-container'} key={service.service_type + "_container"}>
                                        <div className="service-preview"
                                             onClick={_.partial(this.handleSelectService, service.id)}>
                                            <div className="icon-container">
                                                <img src={service.icon_on_light_path} alt={service.name} height={'80px'}/>
                                            </div>
                                            <div className="text-container">
                                                <div className="service-name-container">
                                                    {service.name}
                                                </div>
                                            </div>
                                        </div>
                                        {this.renderUnavailabilityOverlay(service)}
                                        <AnimateHeight id={service.service_type + '_expanded'}
                                                       duration={500}
                                                       height={(this.selectedService() && this.selectedService().id === service.id) ? 'auto' : 0}>
                                            <div className="service-expanded">
                                                <div className="short-description-container">
                                                    {service.one_liner}
                                                </div>

                                                <div className="whats-included-container">
                                                    <div className="whats-included-text"
                                                         onClick={this.openWhatsIncludedDialog}>
                                                        {i18n.t('home.hero-widget.whats-included')}
                                                    </div>
                                                </div>

                                                <div className="service-customizer-container">
                                                    <ProductCustomizer isOptional={false}
                                                                       isMember={this.props.isMember}
                                                                       selectedProductOptions={this.state.selectedProductOptions[service.service_type]}
                                                                       onItemSelectionChange={this.itemsUpdated}
                                                                       showExplanation={false}
                                                                       serviceType={service.service_type}
                                                                       onSubmissionRequest={this.handleSubmit}
                                                                       icons={this.props.icons}
                                                                       selectedAddressId={this.state.selectedAddressId}
                                                                       selectedDistrictId={this.state.selectedDistrictId}
                                                                       clientId={this.props.client_id}
                                                    />
                                                </div>
                                            </div>
                                            {this.showSubmitButton() &&
                                            <div className="mobile-service-actions">
                                                <button
                                                    className={'btn btn-primary continue-cta polyxena-submit-button'}
                                                    onClick={this.handleSubmit}>{this.submitTextForService(service)}
                                                </button>
                                            </div>}
                                        </AnimateHeight>

                                    </div>
                                );
                            }.bind(this))}
                        </MediaQuery>
                        <MediaQuery minWidth={BreakPoints.smMax}>
                            {!this.selectedService() &&
                            <div className="desktop-services-container">
                                <div className="desktop-service-row">
                                    {_.map(this.mainServices(), function (service) {
                                        return (
                                            this.renderDesktopService(service)
                                        );
                                    }.bind(this))}
                                </div>

                                <div className="desktop-service-row">
                                    {_.map(this.secondaryServices(), function (service) {
                                        return (
                                            this.renderDesktopService(service, 'secondary-service')
                                        );
                                    }.bind(this))}
                                </div>
                            </div>}
                            {this.selectedService() &&
                            <div className={'desktop-selected-service-container'}>
                                <div className="close-button-container"
                                     onClick={_.partial(this.handleSelectService, null)}>
                                    <img src={this.props.close_button_image} alt="close"/>
                                </div>
                                <div className="header-container">
                                    <div className="icon-container">
                                        <img src={this.selectedService().icon_on_light_path} alt={this.selectedService().name} height='80px' />
                                    </div>
                                    <div className="service-name-container ">
                                        {this.selectedService().name}
                                    </div>
                                </div>
                                <div className="one-liner-container">
                                    {this.selectedService().one_liner}
                                </div>

                                <AnimateHeight id={this.selectedService().service_type + '_desktop_expanded'}
                                               duration={500}
                                               height={(this.selectedService() && this.state.whatsIncludedSectionExpanded) ? 'auto' : 0}>
                                    <div className="whats-included-desktop-container">
                                        <div className="header-container">
                                            {i18n.t('home.hero-widget.whats-included')}
                                        </div>
                                        <div
                                            dangerouslySetInnerHTML={{__html: this.props.whats_included_text[this.selectedService().service_type]}}/>
                                    </div>
                                </AnimateHeight>

                                <div className="learn-more-link-container"
                                     onClick={this.handleWhatsIncludedDesktopClick}>
                                    {i18n.t('home.hero-widget.learn-more')}
                                    {!this.state.whatsIncludedSectionExpanded &&
                                    <span className={'glyphicon glyphicon-chevron-down arrow'}></span>}
                                    {this.state.whatsIncludedSectionExpanded &&
                                    <span className={'glyphicon glyphicon-chevron-up arrow'}></span>}
                                </div>

                                <div className="customizer-cta-container">
                                    <div className="customizer-content">
                                        <div className="service-customizer-container">
                                            <ProductCustomizer isOptional={false}
                                                               isMember={this.props.isMember}
                                                               selectedProductOptions={this.state.selectedProductOptions[this.selectedService().service_type]}
                                                               onItemSelectionChange={this.itemsUpdated}
                                                               showExplanation={false}
                                                               serviceType={this.selectedService().service_type}
                                                               onSubmissionRequest={this.handleSubmit}
                                                               icons={this.props.icons}
                                                               selectedAddressId={this.state.selectedAddressId}
                                                               selectedDistrictId={this.state.selectedDistrictId}
                                                               clientId={this.props.client_id}
                                            />
                                        </div>
                                    </div>
                                    {this.showSubmitButton() &&
                                    <div className={"continue-container"}>
                                        <button className={'btn btn-primary continue-cta polyxena-submit-button'}
                                                onClick={this.handleSubmit}>{this.submitTextForService(this.selectedService())}
                                        </button>
                                    </div>}
                                </div>


                            </div>
                            }

                        </MediaQuery>
                    </div>
                    <MediaQuery maxWidth={BreakPoints.smMax}>
                        {!this.state.moreServicesOpen &&
                        <div className="more-services-cta-container">
                            <div className={classNames({"bottom-link": true, 'active': this.state.moreServicesOpen})}
                                 onClick={this.handleMoreServicesClick}>{i18n.t('home.hero-widget.more-services')}
                            </div>
                        </div>}
                    </MediaQuery>
                </div>

                {this.renderModal()}
            </div>
        );
    }
}

HeroHomepageSelector = withLoader(HeroHomepageSelector);
export default HeroHomepageSelector;
